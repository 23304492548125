$(document).on("turbolinks:load", () => {
	$('#mixology-slider-left-control').click(function(){
		let current = $('.mixology-slider').data('current');
		let previous = getPrevious(current);
		$('.mixology-slider').data('current', previous);

		$('.mixology-recipes-block[data-recipe=' + current + ']').addClass('hidden');
		$('.mixology-recipes-block[data-recipe=' + previous + ']').removeClass('hidden');
	});

	$('#mixology-slider-right-control').click(function(){
		let current = $('.mixology-slider').data('current');
		let next = getNext(current);
		$('.mixology-slider').data('current', next);

		$('.mixology-recipes-block[data-recipe=' + current + ']').addClass('hidden');
		$('.mixology-recipes-block[data-recipe=' + next + ']').removeClass('hidden');
	});

	function getNext(i){
		return (i + 1) % $('.mixology-recipes-block').length;
	}

	function getPrevious(i){
		return (i - 1 + $('.mixology-recipes-block').length)  % $('.mixology-recipes-block').length;
	}
}); 