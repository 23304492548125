// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
require("jquery")
require("shop")
require("parallax-grid")
require("slider")

Rails.start()
Turbolinks.start()
ActiveStorage.start()

function setVh() {
	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`);
}

function closeMenuIfOpen(){
	$('#menu-top-right-toggle').prop('checked', false);
	$('label').removeClass('expanded');
}

var isInTopViewportHalf = function (elem) {
    var bounding = elem.getBoundingClientRect();
    return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.top <= (window.innerHeight/2 || document.documentElement.clientHeight/2) &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
};

function setActiveMenu(element){
	$('a.nav-button').removeClass('active');
	element.addClass('active');
}

function scrollTo(element){
	let offset = element.css('scroll-margin-top');
	console.log(offset);
	let pos = element.offset().top - parseInt(offset, 10);

	$("html, body").animate({ scrollTop: pos }, 1000);
	// window.scrollTo({
 //      top: pos,
 //      behavior: "smooth"
 //    });
}


$(document).on("turbolinks:before-cache", () => {
	closeMenuIfOpen();
});

$(document).on("turbolinks:before-render", () => {
	closeMenuIfOpen();
});

$(document).on("turbolinks:load", () => {
	
	$(window).on('resize', () => { setVh(); });
	setVh();
	
	$('#menu-top-right-toggle').change(function(){
		if($(this).prop('checked') == true){
			$('label').addClass('expanded');
		}
		else{
			$('label').removeClass('expanded');
		}
	});

	if(window.location.hash){
		setTimeout(function(){scrollTo($(window.location.hash))}, 500);
	}

	$('a.nav-button').click(function(e){
		if(window.location.pathname == "/"){
			e.preventDefault();
			let link_target = $(this).prop('href');
			let hash = link_target.substring(link_target.indexOf('#'));
			if(hash != link_target){
				// use history.pushState instead of window.location to prevent Firefox Mobile bug
				scrollTo($(hash));
				//$(hash)[0].scrollIntoView();
				//history.pushState({}, '', "/" + hash);
				setActiveMenu($(this));	
			}
			else {
				console.log($(this).prop('href'));
				Turbolinks.visit($(this).prop('href'));
			}
			
		}
		else{
			Turbolinks.visit($(this).prop('href'));
		}
		closeMenuIfOpen();
	})

	if(window.location.pathname == "/"){
		$(window).scroll(function(){
			$('.content').each(function(){
				if(isInTopViewportHalf($(this)[0])){
					let hash = "#" + $(this).attr('id');
					setActiveMenu($('a.nav-button[href="/' + hash +'"]'))
				}
			})
		});

		$('input, textarea').each(
			function() {
				let default_val = $(this).val();
				$(this).focus(function(){
					if($(this).val() == default_val) $(this).val("");
				}).blur(function(){
					if($(this).val().length == 0) $(this).val(default_val);
				})
			}
		);

	}

}); 