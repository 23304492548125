import Client from 'shopify-buy';

var client = ""

function createCheckout() {
	let checkout = client.checkout.create();
	checkout.then((checkout) => {
		sessionStorage.setItem("checkoutId", checkout.id);
	});
	return checkout;
}

function getCheckout() {
	if(sessionStorage.getItem("checkoutId")){
		let checkout = client.checkout.fetch(sessionStorage.getItem("checkoutId")).then((checkout) => {
			if(!checkout.completedAt)
				return checkout
			else
				return createCheckout();
		});
		return checkout
	}
	else {
		return createCheckout();
	}
}

function getCheckoutId() { return sessionStorage.getItem("checkoutId");}

function getCheckoutUrl() { 
	return getCheckout().then((checkout) => {
	  return checkout.webUrl
  });
}

function getNumberOfItemsInCheckout() {
  return getCheckout().then((checkout) => {
  	return checkout.lineItems.reduce((total, lineItem) => total + lineItem.quantity, 0);
  });
}

const product = (img, title, description, price, variantId) => {
	let product = $('.product', '.shop-templates').clone();
	product.attr('data-variant', variantId);
	$('img', product).attr('src', img);
	$('.product-title', product).html(title);
	$('.product-description', product).html(description);
	$('.amount', product).html(price);
	return product
}

const productVariantTile = (title, variantId, available) => {
	let tile = $('.variant', '.shop-templates').clone();
	tile.attr('data-variant', variantId);
	tile.html(title);
	if(!available)
		tile.addClass('unavailable');
	return tile
}

const cart = (paymentDue) => {
	let cart = $('.cart', '.shop-templates').clone();
	$('span.amount', cart).html(paymentDue);
	return cart
}

const item = (img, title, price, variantId, quantity, variant) => {
	let item = $('.line-item', '.shop-templates').clone();
	item.attr('data-variant', variantId);
	$('img', item).attr('src', img);
	$('.product-title', item).html(title);
	$('.product-variants', item).html(variant);
	$('span.amount', item).html(price);
	$('input.item-amount', item).attr('value', quantity);
	return item
}

function renderCartIcon() {
	getNumberOfItemsInCheckout().then((items) => {
		if(items > 0){
		  $('.cart-items').html(items);
		  $('span.items', '.mobile-cart-button').html('(' + items +')')
		}
		else {
			$('.cart-items').html('');
			$('span.items', '.mobile-cart-button').html('')
		}
  });
}

function renderCheckoutButton() {
	getNumberOfItemsInCheckout().then((items) => {
		if(items > 0){
			getCheckoutUrl().then((checkoutUrl => $('.checkout-button-shop').attr('href', checkoutUrl)));
			$('.checkout-button-shop').attr('target', '_blank');
		  $('.checkout-button-shop').removeClass('deactivated');	
		}
		else {
			$('.checkout-button-shop').removeAttr('href');
	    $('.checkout-button-shop').addClass('deactivated');
		}
	});
}

function renderProduct(prod) {
  $('.shop-container').append(
  		product(prod.images[0].src, prod.title, prod.description, prod.variants[0].price.amount, prod.variants[0].id)
  );

  if(prod.variants.length > 1){
  	prod.variants.forEach(function(variant) {
        $('.product-variants', '.product[data-variant="' + prod.variants[0].id + '"]').append(
        	productVariantTile(variant.title, variant.id, variant.available)
        );
  	});

    let activeVariants = $('.variant', '.product[data-variant="' + prod.variants[0].id + '"]').not('.unavailable')
  	activeVariants.first().addClass('active');
  	if(activeVariants.length == 0)
  		$('.cart-button-shop', '.product[data-variant="' + prod.variants[0].id + '"]').addClass('deactivated');

  	$('.variant', '.product[data-variant="' + prod.variants[0].id + '"]').click(function() {
  		$('.variant', '.product[data-variant="' + prod.variants[0].id + '"]').removeClass('active');
  		$(this).addClass('active');
  		if($(this).hasClass('unavailable')){
  			$('.cart-button-shop', $(this).closest('.product')).addClass('deactivated');
  		}
  		else{
  			$('.cart-button-shop', $(this).closest('.product')).removeClass('deactivated');
  		}
  	});

  }
  else{
  	if(!prod.variants[0].available){
  		$('.cart-button-shop', '.product[data-variant="' + prod.variants[0].id + '"]').addClass('deactivated');
  	}
  }

	$('button', '.product[data-variant="' + prod.variants[0].id + '"]').click(function() {
		let input = $('input.item-amount', $(this).parent());
		if($(this).hasClass('plus'))
		  input.val(parseInt(input.val()) + 1);
		else
			input.val(Math.max(parseInt(input.val()) -1 , 0));
	});

	$('.cart-button-shop').click(function() {
		function getVariantId(product) {
			let variants = $('.product-variants', product)
			if(variants.children().length == 0) {
			  return product.data('variant');
		  }
		  else {
        return $('.variant.active', variants).data('variant');
		  }
		}

		if(!$(this).hasClass('deactivated')){
			let product = $(this).closest('.product');
			let variantId = getVariantId(product);
			let quantity = parseInt($('.item-amount', product).val());
			let lineItemsToAdd = [{variantId: variantId, quantity: quantity}];

			if(quantity > 0)
				client.checkout.addLineItems(getCheckoutId(), lineItemsToAdd).then((checkout) => {
	  			renderCartIcon();
				});
			}
	});

  renderCartIcon();
}

function renderCollection(collection) {
  $('.shop-container').empty();

	client.collection.fetchByHandle(collection).then((coll) => {
	  coll.products.forEach(renderProduct);

  });
}

function renderCart() {

	$('.shop-container').empty();

	getCheckout().then((checkout) => {
		$('.shop-container').append(
			cart(checkout.paymentDue.amount)
		)
    renderCheckoutButton();

		checkout.lineItems.forEach(function(lineItem) {
			$('.product-listing', '.shop-container').append(
				item(lineItem.variant.image.src, lineItem.title, lineItem.variant.price.amount, lineItem.id, lineItem.quantity, lineItem.variant.title.includes('Default Title') ? '' : lineItem.variant.title)
		  )
		});

	  $('button').click(function() {
			let input = $('input.item-amount', $(this).parent());
			if($(this).hasClass('plus'))
			  input.val(parseInt(input.val()) + 1);
			else
				input.val(Math.max(parseInt(input.val()) -1 , 0));
			let lineItem = $(this).closest('.line-item').data('variant');
			let quantity = parseInt(input.val());
			let lineItemsToUpdate = [{id: lineItem, quantity: quantity}];
			client.checkout.updateLineItems(getCheckoutId(), lineItemsToUpdate).then((checkout) => {
				$('.product-checkout span.amount', '.cart').html(checkout.paymentDue.amount);
				renderCartIcon();
				renderCheckoutButton();
			})
			if(quantity == 0)
        $(this).closest('.line-item').remove();
		});
	});
}

$(document).on("turbolinks:load", () => {

	if(window.location.pathname.includes('shop')){
		client = Client.buildClient({
			domain: 'berrics-ch.myshopify.com',
			storefrontAccessToken: '8a1205cf41c53bf8181f657b229f2664'
		});

		if($('.nav-button-shop.active').data('shop') == true) {
			renderCollection($('.nav-button-shop.active').data('target'));
		}

		$('.nav-button-shop').click(function() {
			$('.nav-button-shop').removeClass('active');
			$(this).addClass('active');

			if($(this).data('shop') == true) {
				var collection = $(this).data('target')
				renderCollection(collection);
			}
			else {
				if($(this).data('target') == "cart")
					renderCart();
			}
		});

		$('.cart-icon').click(function(){
			renderCart();
		})
	}

});